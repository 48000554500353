import './App.css';
import React from 'react';
import Template from './components/Template';
import Login from './components/Login';

function App() {
  return (
    <div className="App">
      <h1 className='title'>CODE SNIPPETS</h1>
        <Login/>
        
    </div>  
  );
}

export default App;
